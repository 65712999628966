@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

body{
  font-family: 'Montserrat', sans-serif;
}

a{
  font-family: 'Montserrat', sans-serif;
}

p{
  font-family: 'Montserrat', sans-serif;
}

h1{
  font-family: 'Montserrat', sans-serif;
}
h2{
  font-family: 'Montserrat', sans-serif;
}
h3{
  font-family: 'Montserrat', sans-serif;
}
h4{
  font-family: 'Montserrat', sans-serif;
}

.editorClassName.rdw-editor-main {
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -5px;
  padding: 0 15px;
  height: 200px;
}

figure img{
  max-width: 100%;
}